* {
    box-sizing: border-box;
  }
  
  html, body, #root, .container {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
  
  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: black;
  }
  
  .navBar {
    position: sticky;
    top: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    padding: 15px 20px;
    background-color: transparent;
    color: white;
    z-index: 10;
  }
  
  .navButton {
    background: rgba(187, 0, 0, 0.1);
    border-radius: 5px;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 24px;
    margin: 0 4px;
    padding: 15px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .navButton:hover {
    box-shadow: 0 0 10px white, 0 0 5px white;
  }
  
  .centerButton {
    font-size: 4rem;
    padding: 10px 110px;
    color: white;
    background: rgba(197, 36, 36, 0.1);
    border: 2px solid white;
    border-radius: 80px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 5px 15px rgba(0,0,0,0.2);
    animation: glow 2s ease-in-out infinite;
    margin-top: 40px;
  }
  .centerButton2 {
    font-size: 4rem;
    padding: 10px 50px;
    color: white;
    background: rgba(11, 183, 128, 0.1);
    border: 2px solid white;
    border-radius: 80px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 5px 15px rgba(0,0,0,0.2);
    animation: glow2 2s ease-in-out infinite;
  }
  
  @keyframes glow {
    0% {
      box-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #e60000, 0 0 20px #e60000, 0 0 25px #e60000, 0 0 30px #e60000, 0 0 35px #e60000;
    }
    
    100% {
      box-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60000, 0 0 40px #e60000, 0 0 50px #e60000, 0 0 60px #e60000, 0 0 70px #e60000;
    }
  }

  @keyframes glow2 {
    0% {
      box-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #192da0, 0 0 20px #192da0, 0 0 25px #192da0, 0 0 30px #192da0, 0 0 35px #e60000;
    }
    
    100% {
      box-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #192da0, 0 0 40px #192da0, 0 0 50px #192da0, 0 0 60px #192da0, 0 0 70px #e60000;
    }
  }

  .centerButton:hover {
    box-shadow: 0 0 10px white, 0 0 5px white;
    transform: scale(1.1);
  }
  .centerButton2:hover {
    box-shadow: 0 0 10px white, 0 0 5px white;
    transform: scale(1.1);
  }
  .description a {
    color: lightblue;
    text-decoration: none;
  }
  
  .description {
    font-size: 1.8rem;
    color: white;
    text-align: justify;
    /* margin-bottom: 12rem; */
    position: relative;
    padding-bottom: 10%;
    max-width: 1200px;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
    padding: 2rem;
  }
  
  @media only screen and (max-width: 600px) {
    .navBar {
      position: fixed; /* make it fixed for mobile devices */
      flex-direction: row;
      width: 100%; /* ensure it spans full width */
      background-color: black; /* add background color for visibility */
    }
  
    .navButton {
      font-size: 16px;
      padding: 10px;
      margin: 10px 0; /* Increase vertical margin */
    }
  
    .description {
      font-size: 1.4rem;
      padding: 0 5%;
      padding-top: 300px; /* Increase padding to move content further down */
    }
  
    .centerButton {
      font-size: 3rem;
      padding: 5px 20px;
    }
  }
  